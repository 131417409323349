@use "../../global"as *;

.c-card2 {
  display: block;
  text-decoration: none;
  overflow: hidden;
  background: url('../images/bg_type1_1.png') 0 0 repeat;
  border-radius: 0 responsiveClamp(20, 30, 'xs', 'xl') 0 responsiveClamp(20, 30, 'xs', 'xl');
}

.c-card2__image1 {
  aspect-ratio: 552/341;
  margin: 0;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.7s $easeOutQuint;

    @at-root {
      .c-card2:hover & {
        transform: scale(1.1);
      }
    }
  }
}

.c-card2__main1 {
  display: flex;
  justify-content: space-between;
  padding: responsiveClamp(16, 24, 'md', 'xl') responsiveClamp(20, 40, 'md', 'xl');

  &::after {
    display: block;
    content: '';
    width: responsiveClamp(40, 50, 'md', 'xl');
    height: responsiveClamp(40, 50, 'md', 'xl');
    background: url('../images/arrow_right1_3.svg') 0 0 / cover no-repeat;
    transition: all 0.7s $easeOutQuint;

    @at-root {
      .c-card2:hover & {
        transform: translateX(5px);
      }
    }
  }
}

.c-card2__title1 {
  font-family: $font-family-en;
  font-size: responsiveClamp($font-size-lg, $font-size-xl, 'md', 'xl');
  color: $gray-100;
  font-weight: 700;
  line-height: 1;
  margin: 0;

  &>span {
    display: block;
    font-family: $font-family-jp;
    font-weight: 400;
    font-size: responsiveClamp($font-size-xs, $font-size-sm, 'md', 'xl');
    margin: responsiveClamp(4, 8, 'xs', 'xl') 0 0;
  }
}
