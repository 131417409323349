@use "../global"as *;

body {
  &.-loading {
    height: 100vh;
    overflow: hidden;
  }
}

.c-container,
.c-fluid-container {
  padding: 0 responsiveClamp(30, 100, 'xs', '2xl');
}
