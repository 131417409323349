@use "../../global"as *;

.c-card3 {}

.c-card3__image1 {
  overflow: hidden;
  margin: 0;
  aspect-ratio: 784/440;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.7s $easeOutQuint;

    @at-root {
      .c-card3__image1:hover img {
        transform: scale(1.1);
      }
    }
  }
}

.c-card3__main1 {}

.c-card3__list1 {
  margin: responsiveClamp(16, 16, 'xs', 'xl') 0 0;
}

.c-card3__title1 {
  line-height: 1.5;
  margin: responsiveClamp(16, 16, 'xs', 'xl') 0 0;

  &>a {
    color: $gray-900;
    font-size: responsiveClamp($font-size-md, $font-size-lg, 'xs', 'xl');
    font-family: $font-family-jp;
    font-weight: 700;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 3em;

    &:hover {
      text-decoration: none;
    }
  }
}

.c-card3__date1 {
  color: $gray-500;
  font-family: $font-family-jp;
  font-size: responsiveClamp($font-size-sm, $font-size-sm, 'xs', 'xl');
  margin: responsiveClamp(4, 4, 'xs', 'xl') 0 0;
}

.c-card3__text1 {
  display: none;

  @include mq('md', min) {
    display: block;
    font-size: $font-size-sm + px;
    margin: 16px 0 0;
    font-family: $font-family-jp;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
