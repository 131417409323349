@use "../../global"as *;

.c-title1 {
  font-size: responsiveClamp($font-size-xl, $font-size-2xl, 'xs', 'xl');
  color: $gray-100;
  font-family: $font-family-en;
  text-align: center;
  position: relative;
  display: grid;
  gap: responsiveClamp(16, 24, 'xs', 'xl');

  &::before {
    display: block;
    content: '';
    width: 1px;
    height: responsiveClamp(40, 104, 'xs', 'xl');
    background: $gray-100;
    margin: auto;
  }
}
