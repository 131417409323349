@use "../../global"as *;

.c-sns1 {
  list-style-type: none;
  margin: responsiveClamp(16, 24, 'xs', '2xl') 0 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @include mq('md', min) {
    justify-content: start;
  }
}

.c-sns1__item1 {
  &>a {
    display: grid;
    align-items: center;
    justify-content: center;
    background: $gray-100;
    border-radius: 10rem;
    width: 40px;
    height: 40px;
    transition: all 0.7s $easeOutQuint;

    &:hover {
      background: $primary-200;
    }
  }
}
