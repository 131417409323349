@use "../../global"as *;

.p-block6 {
  position: relative;
  background: $gray-100;
  padding: responsiveClamp(64, 104, 'xs', 'xl') 0 0;
  overflow: hidden;

  @include mq('md', min) {
    padding: responsiveClamp(64*2, 104*2, 'xs', 'xl') 0 0;
  }

  &::before {
    display: block;
    content: '';
    width: 100%;
    height: 45%;
    position: absolute;
    top: 0;
    left: 0;
    background: url('../images/bg_type1_1.png') 0 0 repeat;

    @include mq('md', min) {
      opacity: 1;
      height: responsiveClamp(447, 547, 'xs', 'xl');
      top: responsiveClamp(64, 104, 'xs', 'xl');
    }

    @include mq('lg', min) {
      max-width: 1200px;
      margin: auto;
      right: 0;
    }
  }
}

.p-block6__inner1 {
  position: relative;
  padding: 0 responsiveClamp(30, 100, 'xs', 'xl');
}

.p-block6__title1 {
  text-align: center;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.p-block6__swiper1 {
  margin: responsiveClamp(40, 64, 'xs', 'xl') 0 0;

  .swiper {
    overflow: visible;

    @include mq('lg', min) {
      max-width: 784px;
      margin: auto;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 30px;
    height: 30px;
    border-radius: 10rem;
    background: $gray-100;
    border: 1px solid $gray-900;
    transition: all 0.7s $easeOutQuint;

    &:hover {
      background: $primary-500;
    }

    &::after {
      display: none;
    }

    &::before {
      position: relative;
      display: block;
      content: '';
      width: 8px;
      height: 8px;
    }
  }

  .swiper-button-prev {
    left: -15px;

    &::before {
      right: -2px;
      border-left: 2px solid $gray-900;
      border-top: 2px solid $gray-900;
      transform: rotate(-45deg);

      @at-root {
        .swiper-button-prev:hover::before {
          border-color: $gray-100;
        }
      }
    }
  }

  .swiper-button-next {
    right: -15px;

    &::before {
      left: -2px;
      border-right: 2px solid $gray-900;
      border-bottom: 2px solid $gray-900;
      transform: rotate(-45deg);

      @at-root {
        .swiper-button-next:hover::before {
          border-color: $gray-100;
        }
      }
    }
  }

  .swiper-button-disabled {
    opacity: 0;
  }

  .swiper-pagination {
    position: static;
    line-height: 0;
    margin: responsiveClamp(16, 24, 'xs', 'xl') 0 0;
  }

  .swiper-pagination-bullet {
    background: $gray-700;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: $primary-500;
  }
}

.p-block6__swiper1__unit1 {}

.p-block6__button1 {
  text-align: center;
  margin: responsiveClamp(40, 64, 'xs', 'xl') 0 0;
}
