@use "../../global"as *;

.p-kv1 {
  height: 100vh;
  min-height: responsiveClamp(550, 800, 'xs', 'xl');
  overflow: hidden;
  position: relative;
  background: $gray-900;
  position: sticky;
  left: 0;
  top: 0;

  &::before {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url('../images/bg_type1_1.png') 0 0 repeat;
    opacity: 0.95;
    z-index: 1;
  }
}

.p-kv1__inner1 {
  padding: 10px 0;
  height: 100%;

  @include mq('sm', min) {
    padding: 20px 0;
  }
}

.p-kv1__logo1 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: responsiveClamp(200, 227+100, 'xs', 'xl');
  display: grid;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: all 0.7s $easeOutQuint;
  opacity: 0;

  &.-hide {
    opacity: 0 !important;
  }
}

.p-kv1__main1 {
  height: 100%;
  position: relative;
}

.p-kv1__unit1 {
  display: flex;
  height: calc(25% - 10px);
  position: relative;

  @include mq('sm', min) {
    height: calc(25% - 20px);
  }

  &:nth-of-type(1) {
    position: absolute;
    top: 0;
    left: 0;
  }

  &:nth-of-type(2) {
    position: absolute;
    top: 25%;
    left: 0;
  }

  &:nth-of-type(3) {
    position: absolute;
    top: 50%;
    left: 0;
  }

  &:nth-of-type(4) {
    position: absolute;
    top: 75%;
    left: 0;
  }
}

.p-kv1__unit1__group2,
.p-kv1__unit1__group1 {
  display: flex;
  gap: 10px;
  padding-left: 10px;

  @include mq('sm', min) {
    gap: 20px;
    padding-left: 20px;
  }
}

.p-kv1__unit1__group1 {
  animation: right1-1 150s -75s linear infinite;

  @at-root {
    .p-kv1__unit1 {

      &:nth-of-type(4),
      &:nth-of-type(2) {
        .p-kv1__unit1__group1 {
          animation: right2-1 100s -50s linear infinite;
        }
      }
    }
  }
}

.p-kv1__unit1__group2 {
  animation: right1-2 150s linear infinite;

  @at-root {
    .p-kv1__unit1 {

      &:nth-of-type(4),
      &:nth-of-type(2) {
        .p-kv1__unit1__group2 {
          animation: right2-2 100s linear infinite;
        }
      }
    }
  }
}

.p-kv1__image1 {
  aspect-ratio: 408/255;
  min-width: 250px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@keyframes right1-1 {
  0% {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes right1-2 {
  0% {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-200%);
  }
}

@keyframes right2-1 {
  0% {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes right2-2 {
  0% {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-200%);
  }
}
