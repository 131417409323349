@use "../../global"as *;

.p-cta1 {
  padding: responsiveClamp(64*2, 104*2, 'xs', 'xl') 0 responsiveClamp(64, 104, 'xs', 'xl');
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    height: calc(100% - responsiveClamp(64, 104, 'xs', 'xl'));
    position: absolute;
    top: responsiveClamp(64, 104, 'xs', 'xl');
    left: 0;
    background: url('../images/bg_type1_1.png') 0 0 repeat;
    opacity: 0.95;
  }
}

.p-cta1__inner1 {
  position: relative;
  display: grid;
  gap: responsiveClamp(40, 64, 'xs', 'xl');
  padding: 0 responsiveClamp(30, 100, 'xs', 'xl');

  @include mq('lg', min) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
    max-width: 1200px;
    margin: auto;
    box-sizing: content-box;
  }
}

.p-cta1__unit1 {
  @include mq('lg', min) {
    padding: 0 responsiveClamp(40, 64, 'lg', '3xl');
    border-left: 1px solid $gray-800;
  }

  &:nth-of-type(1) {
    padding-left: 0;
    border: none;
  }

  &:nth-of-type(2) {
    padding-right: 0;
  }
}

.p-cta1__unit1__title1 {}

.p-cta1__unit1__main1 {}

.p-cta1__unit1__text1 {
  margin: responsiveClamp(24, 40, 'xs', 'xl') 0 0;
  color: $gray-100;
  font-family: $font-family-jp;
  font-size: responsiveClamp($font-size-sm, $font-size-md, 'xs', 'xl');
  line-height: 1.5;
}

.p-cta1__unit1__button1 {
  margin: responsiveClamp(24, 40, 'xs', 'xl') 0 0;
}

.p-cta1__unit1__link1 {
  margin: responsiveClamp(24, 40, 'xs', 'xl') 0 0;

  @include mq('md', min) {
    text-align: center;
  }
}
