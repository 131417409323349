@use "../../global"as *;

.p-loading1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: $gray-900;
  z-index: 9999;
  display: grid;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0% 100%);
  overflow-y: scroll;
}

.p-loading1__logo1 {
  display: grid;
  gap: responsiveClamp(5, 10, 'xs', 'xl');

  @include mq('lg', min) {
    width: 327px;
    justify-content: center;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.p-loading1__logo1__mark1 {
  width: responsiveClamp(48, 90, 'xs', 'xl');
  margin: 0 auto;
  animation: rotate 20s linear infinite;
}

.p-loading1__logo1__name1 {
  width: responsiveClamp(116, 228, 'xs', 'xl');
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.p-loading1__text1 {
  color: $gray-100;
  font-family: $font-family-en;
  font-size: responsiveClamp($font-size-lg, $font-size-lg, 'xs', 'xl');
  text-align: center;
  margin: responsiveClamp(16, 24, 'xs', 'xl') 0 0;
}
