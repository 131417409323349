@use "../../global"as *;

.c-card1 {
  display: block;
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.7s $easeOutQuint;
    opacity: 0.7;
    z-index: 1;
    background: url('../images/bg_type1_1.png') 0 0 repeat;
    // background: linear-gradient(to top, #000 10%, rgba(#000, 0));

    @include mq('lg', min) {
      opacity: 0.95;
    }

    @at-root {
      .c-card1:hover::before {
        opacity: 0.6;
      }
    }
  }

  &::after {
    position: absolute;
    top: calc(responsiveClamp(20, 25, 'xs', 'xl') * -1);
    left: 0;
    right: 0;
    margin: auto;
    display: grid;
    align-items: center;
    justify-content: center;
    counter-increment: num;
    content: '0'counter(num);
    width: responsiveClamp(40, 50, 'xs', 'xl');
    height: responsiveClamp(40, 50, 'xs', 'xl');
    background: $primary-500;
    z-index: 1;
    color: $gray-100;
    font-family: $font-family-en;
    font-size: responsiveClamp($font-size-md, $font-size-lg, 'xs', 'xl');
    border-radius: 10rem;
    border: 2px solid $primary-500;
    display: none;
    transition: all 0.7s $easeOutQuint;

    @at-root {
      .c-card1:hover::after {
        transform: scale(1.5) translateY(-10px);
      }
    }

    @include mq('xl', min) {
      display: grid;
    }
  }
}

.c-card1__image1 {
  margin: 0;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    transition: all 0.7s $easeOutQuint;

    @at-root {
      .c-card1:hover .c-card1__image1 {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}

.c-card1__main1 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: grid;
  align-items: center;
  z-index: 1;

  &::before {
    display: block;
    content: '';
    width: responsiveClamp(40, 50, 'xs', 'xl');
    height: responsiveClamp(40, 50, 'xs', 'xl');
    position: absolute;
    bottom: 24px;
    left: 0;
    right: 0;
    margin: auto;
    background: url('../images/arrow_right1_3.svg') center center / contain no-repeat;
  }
}

.c-card1__main1__inner1 {}

.c-card1__title1 {
  color: $gray-100;
  font-size: responsiveClamp($font-size-sm, $font-size-lg, 'xs', 'xl');
  font-weight: 700;
  font-family: $font-family-jp;
  text-align: center;
  line-height: 1.5;
  text-shadow: 0 0 10px rgba($gray-900, 1);

  &>span {
    display: block;
    font-size: responsiveClamp($font-size-xs, $font-size-sm, 'xs', 'xl');
    text-align: center;
  }
}

.c-card1__text1 {
  color: $gray-100;
  font-size: responsiveClamp($font-size-xs, $font-size-sm, 'xs', 'xl');
  font-weight: 700;
  font-family: $font-family-jp;
  text-align: center;
  margin: 8px 0 0;
  line-height: 1.5;
  transition: all 0.7s $easeOutQuint;
  overflow: hidden;

  @include mq('lg', min) {
    height: 0;
  }

  @at-root {
    .c-card1:hover & {
      @include mq('lg', min) {
        height: 4em;
      }
    }
  }

  &::before {
    display: block;
    content: '';
    width: 40px;
    height: 1px;
    background: $gray-100;
    margin: 0 auto 8px;
    transition: all 0.7s $easeOutQuint;

    @include mq('lg', min) {
      width: 0;
    }

    @at-root {
      .c-card1:hover & {
        width: 40px;
      }
    }
  }
}
