@use "../../global"as *;

.c-title3 {
  font-size: responsiveClamp($font-size-3xl, $font-size-4xl, 'xs', 'xl');
  font-family: $font-family-en;
  text-align: center;
  margin: 0;
  line-height: 1.3;
  display: inline-block;
  background: $gradient-1;
  background-size: 200% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientAnimation 5s linear infinite;

  br {
    @include mq('md', min) {
      display: none;
    }
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -100% 0;
  }
}
