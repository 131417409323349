@use "../../global"as *;

.c-button1 {
  display: block;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-family: $font-family-en;
  font-size: $font-size-md + px;
  font-weight: 700;
  padding: 6px 50px;
  background: $gradient-1;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  background-size: 200% 100%;
  animation: gradientAnimation 5s linear infinite;

  &::before {
    display: block;
    content: '';
    position: absolute;
    inset: 0;
    top: 0;
    left: 0;
    padding: 1px;
    border-radius: 5px;
    background: $gradient-1;
    mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: exclude;
    background-size: 200% 100%;
    animation: gradientAnimation 5s linear infinite;
  }

  &::after {
    display: block;
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto;
    background: url('../images/arrow_right1_1.svg') 0 0 / cover no-repeat;
    transition: all 0.7s $easeOutQuint;

    @at-root {
      .c-button1:hover::after {
        right: 15px;
      }
    }
  }
}
