// Breakpoint
$screen-xs: 360;
$screen-sm: 640;
$screen-md: 768;
$screen-lg: 1024;
$screen-xl: 1280;
$screen-2xl: 1440;
$screen-3xl: 1536;

$screen: ('xs': $screen-xs,
  'sm': $screen-sm,
  'md': $screen-md,
  'lg': $screen-lg,
  'xl': $screen-xl,
  '2xl': $screen-2xl,
  '3xl': $screen-3xl) !default;

// Colors
$gray-100: #ffffff;
$gray-200: #e6e6e6;
$gray-300: #cccccc;
$gray-400: #b3b3b3;
$gray-500: #999999;
$gray-600: #808080;
$gray-700: #666666;
$gray-800: #4d4d4d;
$gray-900: #1a1a1a;
$primary-100: #CBC1E8;
$primary-200: #B0A1DD;
$primary-300: #9682D2;
$primary-400: #7C63C6;
$primary-500: #6244BB;
$primary-600: #52399C;
$primary-700: #412D7D;
$primary-800: #31225D;
$primary-900: #21173E;
$secondary-100: #fde3e3;
$secondary-200: #facccc;
$secondary-300: #f8afaf;
$secondary-400: #f59494;
$secondary-500: #f16f6f;
$secondary-600: #cd1313;
$secondary-700: #9a0e0e;
$secondary-800: #660a0a;
$secondary-900: #330505;
$other-1: #F1EEE8;
$gradient-1: linear-gradient(to right, #FFC817 0%, #6244bb 16%, #81C243 32%, #03ACA2 48%, #EF6759 64%, #FE9226 80%, #FFC817 100%);
$error-100: #f5cccc;
$error-200: #ef4343;
$error-300: #c73838;
$warning-100: #f8e3be;
$warning-200: #f59f0a;
$warning-300: #ce8509;
$success-100: #c1e1d6;
$success-200: #10b77f;
$success-300: #0d9b6c;

// Font Family
$font-family-en: "Ubuntu",
sans-serif;
$font-family-jp: YakuHanJP_Noto,
"Noto Sans JP",
sans-serif;

// Font Size
$font-size-xxs: 10;
$font-size-xs: 12;
$font-size-sm: 14;
$font-size-md: 16;
$font-size-lg: 18;
$font-size-xl: 20;
$font-size-2xl: 24;
$font-size-3xl: 30;
$font-size-4xl: 36;
$font-size-5xl: 48;
$font-size-6xl: 60;
$font-size-7xl: 72;
$font-size-8xl: 96;

// Easing
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);

// SnowMonkey OverRide

:root {
  --_global--container-max-width: 1200px;
}
