@use "../../global"as *;

.c-label1 {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.c-label1__item1 {
  &>a {
    background: $primary-500;
    display: block;
    text-decoration: none;
    color: $gray-100;
    font-size: responsiveClamp($font-size-xs, $font-size-xs, 'xs', 'xl');
    padding: 4px 8px;
    border-radius: 4px;
    transition: all 0.7s $easeOutQuint;
    font-family: $font-family-jp;

    &:hover {
      background: $primary-700;
    }
  }
}
