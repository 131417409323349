@use "../../global"as *;

.p-footer1 {
  padding: responsiveClamp(64, 104, 'xs', '2xl') responsiveClamp(30, 100, 'xs', '2xl') 0;
  background: url('../images/bg_type1_1.png') 0 0 repeat;
}

.p-footer1__inner1 {
  @include mq('md', min) {
    display: grid;
    grid-template-columns: 264px 1fr;
    gap: responsiveClamp(40, 64, 'xs', 'xl');
  }

  @include mq('lg', min) {
    max-width: 992px;
    margin: auto;
  }
}

.p-footer1__main1 {}

.p-footer1__logo1 {
  width: responsiveClamp(200, 264, 'xs', '2xl');
  margin: auto;

  @include mq('md', min) {
    margin: 0;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.p-footer1__list1 {
  margin: responsiveClamp(16, 24, 'xs', 'xl') 0 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  gap: 8px;
}

.p-footer1__list1__item1 {}

.p-footer1__list2 {}

.p-footer1__nav1 {
  margin: responsiveClamp(24, 40, 'xs', '2xl') 0 0;
  padding: responsiveClamp(24, 40, 'xs', '2xl') 0 0;
  border-top: 1px solid rgba($gray-200, 0.2);
  display: grid;
  gap: 8px;

  @include mq('md', min) {
    border: none;
    padding: 0;
    margin: 0;
  }

  @include mq('lg', min) {
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 40px;
    border-left: 1px solid rgba($gray-100, 0.2);
    padding: 0 0 0 responsiveClamp(40, 75, 'lg', '2xl');
  }
}

.p-footer1__nav1__list1 {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
  gap: 8px;

  &>li {
    @include mq('lg', min) {
      white-space: nowrap;
    }

    &>a {
      display: flex;
      align-items: center;
      color: $gray-100;
      text-decoration: none;
      font-size: responsiveClamp($font-size-sm, $font-size-sm, 'xs', '2xl');
      font-weight: 700;
      gap: 8px;
      transition: all 0.7s $easeOutQuint;

      &:hover {
        opacity: 0.5;
      }

      &::before {
        display: block;
        content: '';
        width: 10px;
        max-width: 10px;
        height: 10px;
        border: 2px solid $gray-100;
        border-radius: 10rem;
      }
    }
  }
}

.p-footer1__nav1__list2 {
  list-style-type: none;
  margin: responsiveClamp(16, 16, 'xs', '2xl') 0 responsiveClamp(8, 16, 'xs', '2xl') 1.3em;
  padding: 0;
  display: grid;
  gap: 8px;

  @include mq('lg', min) {
    margin-left: 0;
  }

  &>li {
    &>a {
      display: flex;
      text-decoration: none;
      gap: 8px;
      color: $gray-100;
      font-size: responsiveClamp($font-size-xs, $font-size-sm, 'xs', '2xl');
      transition: all 0.7s $easeOutQuint;

      &:hover {
        opacity: 0.5;
      }

      &::before {
        position: relative;
        top: 0.65em;
        display: block;
        content: '';
        width: 5px;
        height: 5px;
        background: $gray-100;
        border-radius: 10rem;
      }
    }
  }
}

.p-footer1__copy1 {
  background: $gray-100;
  text-align: center;
  display: block;
  margin: responsiveClamp(64, 104, 'xs', '2xl') calc(responsiveClamp(30, 100, 'xs', '2xl') * -1) 0;
  font-size: responsiveClamp($font-size-xs, $font-size-sm, 'xs', '2xl');
  padding: responsiveClamp(8, 16, 'xs', '2xl') 0;
  font-family: $font-family-en;
  font-weight: 400;
}
