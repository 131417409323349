@use "../../global"as *;

.c-button3 {
  display: block;
  background: $primary-500;
  color: $gray-100;
  font-size: responsiveClamp($font-size-lg, $font-size-xl, 'xs', 'xl');
  text-decoration: none;
  font-weight: 700;
  text-align: center;
  padding: responsiveClamp(16, 24, 'xs', 'xl') responsiveClamp(40, 64, 'xs', 'xl');
  border-radius: 4px;
  position: relative;
  transition: all 0.7s $easeOutQuint;

  &:hover {
    background: $primary-600;
  }

  &::before {
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: responsiveClamp(24, 40, 'xs', 'xl');
    margin: auto;
    background: url('../images/arrow_right1_4.svg') 0 0 / cover no-repeat;
    transition: all 0.7s $easeOutQuint;

    @at-root {
      .c-button3:hover::before {
        transform: translateX(5px);
      }
    }
  }
}
