@use "../../global"as *;

.p-heading1 {
  position: relative;
  background: $gray-900 url('../images/heading1_img1_1.png') 0 0 / cover no-repeat;
  padding: responsiveClamp(78, 115, 'xs', 'xl') 0 0;

  &::before {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url('../images/bg_type1_1.png') 0 0 repeat;
  }
}

.p-heading1__inner1 {
  position: relative;
  padding: responsiveClamp(40, 64, 'xs', 'xl') 0;
}

.p-heading1__main1 {}

.p-heading1__title1 {
  display: grid;
  color: $gray-100;
  text-align: center;
  line-height: 1.5;
  font-family: $font-family-jp;
  font-size: responsiveClamp($font-size-xl, $font-size-3xl, 'xs', 'xl');

  &>span {
    display: block;
    font-size: responsiveClamp($font-size-sm, $font-size-md, 'xs', 'xl');
    font-family: $font-family-en;
    text-transform: lowercase;
  }

  &::before {
    display: block;
    content: '';
    width: 1px;
    height: responsiveClamp(30, 40, 'md', 'xl');
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: $gray-100;
  }
}
