@use "../../global"as *;

.l-contents__container>.p-breadcrumbs-wrapper:first-child,
.l-contents__container>.u-slim-width>.p-breadcrumbs-wrapper:first-child {
  padding: responsiveClamp(8, 24, 'xs', 'xl') responsiveClamp(30, 100, 'xs', '2xl');
  margin: 0 calc(responsiveClamp(30, 100, 'xs', '2xl') * -1);
  background: $other-1;

  @include mq('lg', min) {
    width: 100vw;
    max-width: initial;
  }
}

.c-breadcrumbs {
  max-width: 1200px;
  margin: auto;

  .c-breadcrumbs__item {
    font-size: responsiveClamp($font-size-xs, $font-size-sm, 'xs', 'xl');
  }
}
