@use "../../global"as *;

.p-block4 {
  position: relative;
  padding: responsiveClamp(64, 104, 'xs', 'xl') 0 responsiveClamp(40, 64, 'xs', 'xl');
  background: $gray-100;

  &::before {
    display: block;
    content: '';
    width: 100%;
    height: responsiveClamp(100, 187, 'xs', 'xl');
    position: absolute;
    bottom: 0;
    left: 0;
    background: $other-1;
  }

  &::after {
    display: block;
    content: '';
    width: 30px;
    height: 20px;
    position: absolute;
    bottom: -16px;
    left: 0;
    right: 0;
    margin: auto;
    background: $other-1;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
  }
}

.p-block4__inner1 {
  position: relative;
  padding: 0 responsiveClamp(30, 100, 'xs', 'xl');
  max-width: 1200px;
  margin: auto;
  box-sizing: content-box;
}

.p-block4__title1 {
  position: relative;
  z-index: 1;
}

.p-block4__list1 {
  list-style-type: none;
  margin: responsiveClamp(40, 64, 'xs', 'xl') 0 0;
  padding: 0;
  display: grid;
  gap: 24px;

  @include mq('md', min) {
    grid-template-columns: repeat(3, 1fr);
    gap: 1px;
  }

  @include mq('lg', min) {
    gap: responsiveClamp(24, 48, 'xs', 'xl');
  }
}

.p-block4__list1__item1 {
  &:nth-of-type(1) {
    .c-card2 {
      @include mq('md', min) {
        border-radius: 0 0 0 responsiveClamp(20, 30, 'xs', 'xl');
      }

      @include mq('lg', min) {
        border-radius: 0 responsiveClamp(20, 30, 'xs', 'xl') 0 responsiveClamp(20, 30, 'xs', 'xl');
      }
    }
  }

  &:nth-of-type(2) {
    .c-card2 {
      @include mq('md', min) {
        border-radius: 0;
      }

      @include mq('lg', min) {
        border-radius: 0 responsiveClamp(20, 30, 'xs', 'xl') 0 responsiveClamp(20, 30, 'xs', 'xl');
      }
    }
  }

  &:nth-of-type(3) {
    .c-card2 {
      @include mq('md', min) {
        border-radius: 0 responsiveClamp(20, 30, 'xs', 'xl') 0 0;
      }

      @include mq('lg', min) {
        border-radius: 0 responsiveClamp(20, 30, 'xs', 'xl') 0 responsiveClamp(20, 30, 'xs', 'xl');
      }
    }
  }
}
