@use "../../global"as *;

.c-title2 {
  font-family: $font-family-en;
  font-weight: 700;
  text-align: center;
  font-size: responsiveClamp($font-size-3xl, $font-size-4xl, 'xs', 'xl');
  line-height: 1;
  position: relative;
  color: $gray-900;

  &::before {
    display: block;
    content: '';
    width: 1px;
    height: responsiveClamp(40, 84, 'xs', 'xl');
    position: absolute;
    bottom: calc(responsiveClamp(40+16, 84+24, 'xs', 'xl') * -1);
    left: 0;
    right: 0;
    margin: auto;
    background: $gray-900;
  }
}
