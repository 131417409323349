@use "../../global"as *;

.l-header {
  // background: rgba($gray-900, 0.8);
  box-shadow: none;

  &.-fixed {
    background: rgba($gray-900, 0.8);

    .l-header__content .c-site-branding__title {
      opacity: 1;
    }
  }

  @at-root {
    body.home & {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      transition: all 0.7s $easeOutQuint;
    }
  }

  .l-1row-header__branding {
    padding: responsiveClamp(10, 30, 'xs', 'xl') 0;
  }

  .c-site-branding__title {
    &>a {
      display: block;
      width: responsiveClamp(142, 204, 'xs', 'xl');
      height: responsiveClamp(38, 55, 'xs', 'xl');
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      background: url('../images/logo_funmake1_1.svg') 0 0 / cover no-repeat;
    }

    @at-root {
      body.home .l-header__content .c-site-branding__title {
        opacity: 0;
        pointer-events: none;
        transition: all 0.7s $easeOutQuint;
      }
    }
  }

  .c-navbar {
    @include mq('xl', min) {
      gap: responsiveClamp(20, 30, 'xl', '3xl');
    }
  }

  .menu-item {
    &>a {
      position: relative;
      color: $gray-100;
      font-size: $font-size-sm + px;

      @include mq('xl', min) {
        padding: 16px 0;
      }

      &:hover {
        color: $gray-100;
      }

      &::after {
        display: block;
        content: '';
        width: 0;
        height: 5px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: $primary-500;
        transition: all 0.7s $easeOutQuint;

        @at-root {
          .l-header .menu-item>a:hover::after {
            width: 100%;
          }
        }
      }
    }

    &:has(.c-navbar__submenu) {
      &>a::after {
        display: none;
      }
    }
  }

  .current-menu-item {
    &>a {
      color: $gray-100;
    }
  }

  .c-navbar__submenu {
    .menu-item {
      border-top: 1px solid rgba(#fff, 0.2);

      &:first-child {
        border: none;
      }

      &>a {
        font-size: $font-size-sm + px;
        line-height: 1.5;
        white-space: nowrap;
        padding: 16px 24px;

        &::after {
          display: none;
        }
      }
    }
  }

  .c-hamburger-btn {
    position: relative;
    right: -20px;
    width: 50px;
    height: 50px;
    background: $gray-900;
    border-radius: 10rem;
  }

  .c-hamburger-btn__bar {
    background: $gray-100;
  }

  .c-hamburger-btn__label {
    color: $gray-100;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
  }

  .l-header__drop-nav {
    .p-drop-nav {
      background: rgba($gray-900, 0.8);
    }
  }

  .u-invisible-md-down {
    display: none !important;

    @include mq('xl', min) {
      display: block !important;
    }
  }

  .u-invisible-lg-up {
    display: block !important;

    @include mq('xl', min) {
      display: none !important;
    }
  }

  .p-global-nav {
    @include mq('xl', min) {
      display: flex;
      align-items: center;
      gap: responsiveClamp(20, 40, 'xl', '3xl');
    }
  }

  // スクロールしたら出るナビ
  .l-header__drop-nav {
    display: none !important;

    @include mq('xl', min) {
      display: block !important;
    }

    .p-global-nav {
      gap: responsiveClamp(20, 40, 'xs', 'xl');
      justify-content: end;
    }

    .c-site-branding {
      margin-right: auto;
      padding: responsiveClamp(10, 30, 'xs', 'xl') 0;
    }
  }
}


// ハンバーガーボタン押したら出るナビ
.c-drawer {
  background: $gray-900;

  .menu-item {
    border-top: 1px solid rgba($gray-100, 0.1);
    font-size: 14px;
    font-family: $font-family-jp;

    &:first-child {
      border: none;
    }
  }

  .p-search-form {
    display: none;
  }

  .c-button1 {
    margin: 15px 0 0;
  }
}
