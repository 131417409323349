@use "../../global"as *;

.p-block1 {
  position: relative;
  padding: 0 responsiveClamp(30, 100, 'xs', 'xl');
  z-index: 1;
  background: $gray-100;
}

.p-block1__inner1 {
  padding: 1px;

  @include mq('lg', min) {
    max-width: 1200px;
    margin: auto;
  }
}

.p-block1__title1 {
  margin: calc(responsiveClamp(162, 193+64, 'xs', 'xl') * -1) 0 0;
}

.p-block1__swiper1 {
  position: relative;
  margin: 24px 0 0;

  .swiper {}

  .swiper-button-prev,
  .swiper-button-next {
    width: 30px;
    height: 30px;
    border-radius: 10rem;
    background: $gray-100;
    border: 1px solid $gray-900;
    transition: all 0.7s $easeOutQuint;

    &:hover {
      background: $primary-500;
    }

    &::after {
      display: none;
    }

    &::before {
      position: relative;
      display: block;
      content: '';
      width: 8px;
      height: 8px;
    }
  }

  .swiper-button-prev {
    left: -15px;

    &::before {
      right: -2px;
      border-left: 2px solid $gray-900;
      border-top: 2px solid $gray-900;
      transform: rotate(-45deg);

      @at-root {
        .swiper-button-prev:hover::before {
          border-color: $gray-100;
        }
      }
    }
  }

  .swiper-button-next {
    right: -15px;

    &::before {
      left: -2px;
      border-right: 2px solid $gray-900;
      border-bottom: 2px solid $gray-900;
      transform: rotate(-45deg);

      @at-root {
        .swiper-button-next:hover::before {
          border-color: $gray-100;
        }
      }
    }
  }

  .swiper-button-disabled {
    opacity: 0;
  }

  .swiper-pagination {
    position: static;
    line-height: 0;
    margin: responsiveClamp(16, 24, 'xs', 'xl') 0 0;
  }

  .swiper-pagination-bullet-active {
    background: $primary-500;
  }
}

.p-block1__swiper1__unit1 {
  text-decoration: none;
  display: block;
}

.p-block1__swiper1__unit1__image1 {
  aspect-ratio: 264/148;
  margin: 0;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.7s $easeOutQuint;

    @at-root {
      .p-block1__swiper1__unit1:hover & {
        transform: scale(1.1);
      }
    }
  }
}

.p-block1__swiper1__unit1__title1 {
  color: $gray-900;
  font-weight: 700;
  font-size: $font-size-sm + px;
  font-family: $font-family-jp;
  line-height: 1.5;
  margin: responsiveClamp(8, 16, 'xs', 'xl') 0 0;

  @at-root {
    .p-block1__swiper1__unit1:hover & {
      text-decoration: underline;
    }
  }
}
