@use "../../global"as *;

.c-button2 {
  display: block;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-family: $font-family-en;
  font-size: $font-size-md + px;
  font-weight: 700;
  padding: 6px 20px;
  position: relative;
  border: 1px solid $primary-400;
  color: $primary-400;

  &::after {
    display: block;
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto;
    background: url('../images/arrow_right1_2.svg') 0 0 / cover no-repeat;
    transition: all 0.7s $easeOutQuint;

    @at-root {
      .c-button2:hover::after {
        right: 15px;
      }
    }
  }
}
