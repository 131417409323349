@use "../../global"as *;

// SnowMonkey

.l-contents:has(.alignfull:last-child) {
  &+.p-cta1 {
    padding-top: responsiveClamp(64, 104, 'xs', 'xl');

    &::before {
      top: 0;
      height: 100%;
    }
  }
}

.l-contents__inner {
  &:has(.alignfull:first-child) {
    margin: 0;
  }

  &:has(.alignfull:last-child) {
    padding-bottom: 0;
  }
}

.c-entry {

  // ページタイトル
  .c-entry__header {
    @at-root {

      body.blog &,
      body.page & {
        display: none;
      }
    }

    .c-entry__title {
      font-family: $font-family-jp;
      font-size: responsiveClamp($font-size-2xl, $font-size-3xl, 'xs', 'xl');
      line-height: 1.5;
    }
  }

  // ブログ記事一覧
  .p-archive {
    .c-entries {
      &.c-entries--simple {
        display: grid;
        gap: responsiveClamp(40, 64, 'xs', 'xl');
        border: none;

        @include mq('md', min) {
          grid-template-columns: repeat(2, 1fr);
          gap: responsiveClamp(24, 48, 'xs', 'xl');
        }

        @include mq('xl', min) {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }

    .c-entries__item {
      border: none;

      &>a {
        padding: 0;
      }
    }

    .c-entry-summary__figure {
      float: none;
      width: 100%;
      margin: 0;
      aspect-ratio: 16/9;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.7s $easeOutQuint;

        @at-root {
          .c-entries__item:hover .c-entry-summary__figure img {
            transform: scale(1.1);
          }
        }
      }
    }

    .c-entry-summary__term {
      top: 8px;
      right: 8px;
      border-radius: 4px;
      font-family: $font-family-jp;
      font-size: responsiveClamp($font-size-xs, $font-size-xs, 'xs', 'xl');
    }

    .c-entry-summary__body {
      display: grid;
      gap: 8px;
      margin: responsiveClamp(8, 16, 'xs', 'xl') 0 0;

      @at-root {
        .c-entries__item>a:hover .c-entry-summary__body {
          opacity: 1;
        }
      }
    }

    .c-entry-summary__header {
      margin: 0;
    }

    .c-entry-summary__title {
      font-family: $font-family-jp;
      color: $gray-900;
      font-size: responsiveClamp($font-size-md, $font-size-md, 'xs', 'xl');
      line-height: 1.5;

      @at-root {
        .c-entries__item:hover .c-entry-summary__title {
          text-decoration: underline;
          color: $gray-900;
        }
      }
    }

    .c-entry-summary__content {
      display: block;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $gray-500;
      font-size: responsiveClamp($font-size-xs, $font-size-sm, 'xs', 'xl');
    }

    .c-entry-summary__meta {
      margin: 0;
      grid-row-start: 1;
    }

    .c-meta__item {
      &.c-meta__item--published {
        color: $gray-500;
        font-family: $font-family-jp;
        font-size: responsiveClamp($font-size-xs, $font-size-sm, 'xs', 'xl');
        line-height: 1.5;
        font-weight: 400;
      }

      &.c-meta__item--categories {}
    }
  }

  // 記事の前次リンク
  .c-prev-next-nav {
    border-top: 1px solid $gray-900;
    padding: responsiveClamp(24, 40, 'xs', 'xl') 0 0;
    display: flex;
    justify-content: space-between;

    .c-prev-next-nav__item {
      width: auto;
      margin: 0;
    }

    .c-prev-next-nav__item>a {
      background: none;
      color: $gray-900;
      padding: 0;
    }

    .c-prev-next-nav__item-figure {
      display: none;
    }

    .c-prev-next-nav__item-title {
      display: none;
    }
  }

  // 関連記事
  .p-related-posts {
    margin: responsiveClamp(64, 104, 'xs', 'xl') 0 0;

    .c-entries {
      border: none;
      display: grid;
      gap: responsiveClamp(24, 48, 'xs', 'xl');

      @include mq('md', min) {
        grid-template-columns: repeat(2, 1fr);
      }

      .c-entries__item {
        border: none;

        &>a {
          padding: 0;
          display: grid;
          gap: responsiveClamp(8, 16, 'xs', 'xl');
        }

        .c-entry-summary__figure {
          float: none;
          width: 100%;
          margin: 0;
          aspect-ratio: 16/9;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.7s $easeOutQuint;
          }
        }

        .c-entry-summary__body {
          margin: responsiveClamp(8, 16, 'xs', 'xl') 0 0;
        }

        .c-entry-summary__header {
          margin: 0;
        }

        .c-entry-summary__title {
          font-family: $font-family-jp;
          font-size: responsiveClamp($font-size-md, $font-size-md, 'xs', 'xl');
          line-height: 1.5;
          text-decoration: underline;

          @at-root {
            .c-entry .p-related-posts .c-entries .c-entries__item:hover .c-entry-summary__title {
              text-decoration: none;
            }
          }
        }

        .c-entry-summary__content {
          display: none;
        }

        .c-entry-summary__term {
          top: 8px;
          right: 8px;
          border-radius: 4px;
          font-family: $font-family-jp;
          font-size: responsiveClamp($font-size-xs, $font-size-xs, 'xs', 'xl');
        }

        .c-entry-summary__meta {
          margin: responsiveClamp(8, 16, 'xs', 'xl') 0 0;

          .c-meta__item {
            font-family: $font-family-jp;
            color: $gray-500;
            font-size: responsiveClamp($font-size-xs, $font-size-sm, 'xs', 'xl');
            font-weight: 400;
          }
        }
      }
    }
  }

  // ビジュアルエディタ
  .c-entry__content {
    &>h2 {
      font-family: $font-family-jp;
      font-size: responsiveClamp($font-size-xl, $font-size-2xl, 'xs', 'xl');
      background: none;
      border: none;
      padding: responsiveClamp(16, 24, 'xs', 'xl') 0 0;
      line-height: 1.5;
      border-top: 1px solid $gray-900;
      margin-top: responsiveClamp(40, 64, 'xs', 'xl');
    }

    &>h3 {
      font-family: $font-family-jp;
      font-size: responsiveClamp($font-size-lg, $font-size-xl, 'xs', 'xl');
      background: none;
      border: none;
      padding: 0 0 0 24px;
      line-height: 1.5;
      position: relative;

      &::before {
        display: block;
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        top: 8px;
        left: 0;
        background: $gray-900;
      }
    }

    table,
    ol,
    ul,
    p {
      font-family: $font-family-jp;
      font-size: responsiveClamp($font-size-sm, $font-size-md, 'xs', 'xl');
    }

    .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table {
      &>table {
        border-bottom: 1px solid rgba($gray-300, 0.5);

        tr {
          border-top: 1px solid rgba($gray-300, 0.5);
        }

        td,
        th {
          padding: responsiveClamp(8, 16, 'xs', 'xl') responsiveClamp(16, 24, 'xs', 'xl');
          border: none;
        }
      }
    }

    // ボタン
    .wp-block-snow-monkey-blocks-buttons {

      .wp-block-snow-monkey-blocks-btn {
        display: grid;
        gap: responsiveClamp(8, 16, 'xs', 'xl');

        @include mq('sm', min) {
          display: flex;
        }

        .smb-btn {
          white-space: initial;
          display: flex;
          align-items: center;
          color: $gray-100;
          border-color: $gray-900;
          font-family: $font-family-jp;
          font-size: responsiveClamp($font-size-sm, $font-size-md, 'xs', 'xl');
          gap: responsiveClamp(8, 16, 'xs', 'xl');
          line-height: 1.5;
          width: 100%;
          background: $primary-500;

          &:hover {
            background: $primary-600;
            color: $gray-100;
          }
        }

        &.is-style-ghost {
          .smb-btn {
            white-space: initial;
            display: flex;
            align-items: center;
            color: $gray-900;
            border-color: $gray-900;
            font-family: $font-family-jp;
            font-size: responsiveClamp($font-size-sm, $font-size-md, 'xs', 'xl');
            gap: responsiveClamp(8, 16, 'xs', 'xl');
            line-height: 1.5;
            width: 100%;
            background: none;

            &:hover {
              background: $gray-900;
              color: $gray-100;
            }
          }
        }
      }
    }


    // ボックス
    &>.wp-block-snow-monkey-blocks-box {
      padding: responsiveClamp(24, 40, 'xs', 'xl');

      .smb-box__background {
        border: none;
        background: rgba($gray-200, 0.5);
      }

      h4,
      h3,
      h2 {
        font-family: $font-family-jp;
        font-size: responsiveClamp($font-size-lg, $font-size-xl, 'xs', 'xl');
        background: none;
        border: none;
        border-bottom: 1px solid $gray-900;
        padding: 0 0 responsiveClamp(16, 24, 'xs', 'xl');
        line-height: 1.5;
        margin: 0;
      }

      p {
        margin: responsiveClamp(16, 24, 'xs', 'xl') 0 0;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    // 全幅セクション
    &>.wp-block-snow-monkey-blocks-section-break-the-grid.alignfull {
      padding: 0;

      .smb-section__fixed-background {
        display: none;
        padding: 0;
      }

      .smb-section__contents-wrapper {
        padding: 0;
      }

      .c-row.c-row--lg-middle {
        display: grid;

        .smb-section-break-the-grid__header {
          margin: responsiveClamp(40, 40, 'xs', 'xl') 0 0;
        }

        .smb-section-break-the-grid__body {
          .wp-block-group {
            padding: 0;
          }
        }

        .smb-section__title {
          text-align: center;
          font-family: $font-family-jp;
          font-size: responsiveClamp($font-size-2xl, $font-size-3xl, 'xs', 'xl');

          &::after {
            height: 2px;
            background: $gray-900;
            margin: responsiveClamp(16, 24, 'xs', 'xl') auto 0;
          }
        }

        .smb-section__lede-wrapper {
          justify-content: center;
          margin: responsiveClamp(16, 24, 'xs', 'xl') 0 0;
        }

        .smb-section__lede {
          text-align: center;
          font-family: $font-family-jp;
          font-size: responsiveClamp($font-size-sm, $font-size-md, 'xs', 'xl');
        }

        .c-row__col {
          width: 100%;
          max-width: initial;

          &:nth-of-type(1) {
            max-width: 1200px;
            margin: auto;
          }

          &:nth-of-type(2) {
            grid-row-start: 1;

            .smb-section-break-the-grid__figure {
              aspect-ratio: 16/9;
              overflow: hidden;
              margin: 0 calc(responsiveClamp(30, 100, 'xs', 'xl') * -1) responsiveClamp(24, 40, 'xs', 'xl');

              @include mq('lg', min) {
                aspect-ratio: 19/7;
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: scale(1.5);
              }
            }
          }

          .smb-section-break-the-grid__content.smb-section-break-the-grid__content--w--20.smb-section-break-the-grid__content--p-s.smb-section-break-the-grid__content--horizontal-10.smb-section-break-the-grid__content--remove-outside-p {
            margin: 0;
          }

          .smb-section-break-the-grid__shadow {
            display: none;
          }

          .smb-section-break-the-grid__content {
            padding: 0;
          }

          .smb-section-break-the-grid__figure {
            position: static;
          }
        }
      }

      .c-container {
        max-width: initial;
      }
    }

    // 背景付き全幅見出し
    &>.wp-block-snow-monkey-blocks-section-with-bgimage.alignfull {
      position: relative;

      &.-layer {
        &::before {
          // display: block;
          // content: '';
          // width: 100%;
          // height: 100%;
          // position: absolute;
          // top: 0;
          // left: 0;
          // background: url('../images/bg_type1_1.png') 0 0 repeat;
          // z-index: 1;
          // opacity: 0.9;
        }
      }

      .smb-section__inner {
        position: relative;
        z-index: 1;
        padding: 0 responsiveClamp(30, 100, 'xs', 'xl');
        margin: 0 auto;
      }

      .wp-block-image {
        max-width: responsiveClamp(200, 250, 'xs', 'xl');
      }

      .wp-block-group {
        display: block;
        position: relative;
        margin: responsiveClamp(24, 40, 'xs', 'xl') 0;

        &>p {
          text-align: center;
          margin: 0;
          font-family: $font-family-jp;
          line-height: 1.7;
          font-weight: 700;
          font-size: responsiveClamp($font-size-sm, $font-size-md, 'xs', 'xl');
        }

        &::before {
          display: block;
          content: '';
          width: 50px;
          height: 1px;
          position: absolute;
          top: calc(responsiveClamp(22, 30, 'xs', 'xl') * -1);
          left: 0;
          right: 0;
          margin: auto;
          background: $gray-100;
        }
      }
    }

    // 通常セクション
    &>.wp-block-snow-monkey-blocks-section {
      // margin: responsiveClamp(40, 104, 'xs', 'xl') auto 0;

      // .c-container {
      //   padding: 0;
      // }

      &.alignfull {
        .c-container {
          padding: 0 responsiveClamp(30, 100, 'xs', 'xl');
        }
      }

      .wp-block-snow-monkey-blocks-media-text {
        .smb-media-text__title {
          span {
            word-break: break-all;
            line-height: 1.5;
          }
        }

        // .c-row {
        //   display: grid;
        //   grid-template-columns: 1fr;

        //   .c-row__col {
        //     width: 100%;
        //     max-width: initial;

        //     &:nth-of-type(1) {
        //       grid-column-start: 1;
        //     }

        //     &:nth-of-type(2) {
        //       grid-row-start: 1;
        //       grid-column-start: 1;
        //     }
        //   }

        //   .smb-media-text__figure {
        //     border-radius: 0;
        //     margin: 0;
        //     max-width: initial;
        //     aspect-ratio: 16/9;

        //     img {
        //       width: 100%;
        //       height: 100%;
        //       object-fit: cover;
        //     }
        //   }
        // }

        .smb-media-text__body {
          @include mq('md', min) {
            margin: responsiveClamp(16, 24, 'xs', 'xl') 0 0;
          }
        }
      }

      .wp-block-snow-monkey-blocks-items.service-items {
        .smb-items__item {
          font-family: $font-family-jp;

          &:hover {
            opacity: 1;
          }

          .smb-items__item__figure {
            aspect-ratio: 16/9;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              transition: all 0.7s $easeOutQuint;

              @at-root {
                .c-entry .c-entry__content .wp-block-snow-monkey-blocks-section .smb-items__item:hover .smb-items__item__figure img {
                  transform: scale(1.1);
                }
              }
            }
          }

          .smb-items__item__title {
            font-size: responsiveClamp($font-size-lg, $font-size-xl, 'xs', 'xl');
            line-height: 1.5;
            text-decoration: underline;

            @at-root {
              .c-entry .c-entry__content .wp-block-snow-monkey-blocks-section .smb-items__item:hover .smb-items__item__title {
                text-decoration: none;
              }
            }
          }

          .smb-items__item__lede {
            font-size: responsiveClamp($font-size-sm, $font-size-md, 'xs', 'xl');
          }

          .smb-items__item__content {
            font-size: responsiveClamp($font-size-sm, $font-size-md, 'xs', 'xl');
          }
        }

        .c-row {
          @include mq('md', min) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: responsiveClamp(24, 48, 'xs', 'xl');
          }

          .c-row__col {
            padding: 0;

            @include mq('lg', min) {
              max-width: initial;
            }
          }
        }
      }

      .smb-section__body {
        p {
          margin: responsiveClamp(4, 8, 'xs', 'xl') 0 0;
        }
      }

      .smb-section__title {
        text-align: center;
        font-family: $font-family-jp;
        font-size: responsiveClamp($font-size-2xl, $font-size-3xl, 'xs', 'xl');

        &::after {
          height: 2px;
          background: $gray-900;
          margin: responsiveClamp(16, 24, 'xs', 'xl') auto 0;

          @at-root {
            .c-entry .c-entry__content .wp-block-snow-monkey-blocks-section .smb-section__title:has(span.has-sm-text-alt-color)::after {
              background: $gray-100;
            }
          }
        }
      }

      .smb-media-text__title {
        font-family: $font-family-jp;
        font-weight: 700;
        font-size: responsiveClamp($font-size-lg, $font-size-xl, 'xs', 'xl');
      }

      // 会社概要の特徴3個
      &:has(.smb-section__dividers) {
        padding: 0;

        @include mq('md', min) {
          max-width: 1000px;
          margin: responsiveClamp(64, 64, 'xs', 'xl') auto;
        }

        .smb-section__dividers {
          display: none;
        }

        .wp-block-snow-monkey-blocks-grid {
          border-top: 1px solid $gray-900;
          display: block;
          padding: 0 !important;

          @include mq('md', min) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 0 responsiveClamp(24, 48, 'xs', 'xl');
          }

          &>h2 {
            font-family: $font-family-en;
            font-weight: 700;
            text-align: center;
            font-size: responsiveClamp($font-size-lg, $font-size-xl, 'xs', 'xl');
            color: $gray-100;
            background: $primary-500;
            width: responsiveClamp(100, 150, 'xs', 'xl');
            height: responsiveClamp(100, 150, 'xs', 'xl');
            border-radius: 10rem;
            display: grid;
            align-items: center;
            justify-content: center;
            margin: responsiveClamp(24, 40, 'xs', 'xl') auto 0;

            @include mq('md', min) {
              margin: 0 auto;
            }

            &:first-child {
              margin-top: 0;
            }

            &:nth-of-type(1) {
              @include mq('md', min) {
                grid-row-start: 1;
                grid-column-start: 1;
              }
            }

            &:nth-of-type(2) {
              @include mq('md', min) {
                grid-row-start: 1;
                grid-column-start: 2;
              }
            }

            &:nth-of-type(3) {
              @include mq('md', min) {
                grid-row-start: 1;
                grid-column-start: 3;
              }
            }
          }

          &>p {
            font-family: $font-family-jp;
            font-size: responsiveClamp($font-size-sm, $font-size-md, 'xs', 'xl');
            margin: responsiveClamp(16, 24, 'xs', 'xl') 0 0;

            &:nth-of-type(1) {
              @include mq('md', min) {
                grid-row-start: 2;
                grid-column-start: 1;
              }
            }

            &:nth-of-type(2) {
              @include mq('md', min) {
                grid-row-start: 2;
                grid-column-start: 2;
              }
            }

            &:nth-of-type(3) {
              @include mq('md', min) {
                grid-row-start: 2;
                grid-column-start: 3;
              }
            }
          }
        }
      }
    }

    // グループ
    &>.wp-block-group {
      margin: responsiveClamp(40, 104, 'xs', 'xl') auto 0;
    }

    // 子ページ一覧
    &>.smb-child-pages {
      margin: responsiveClamp(64, 104, 'xs', 'xl') auto 0;

      .p-child-pages__title {
        color: $gray-900;
        font-family: $font-family-jp;
      }

      .c-entry-summary__figure {
        margin: 0;
        aspect-ratio: 16/9;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.7s $easeOutQuint;
        }
      }

      .c-entry-summary__header {
        margin: responsiveClamp(8, 8, 'xs', 'xl') 0 0;
      }

      .c-entry-summary__title {
        text-decoration: underline;
        font-family: $font-family-jp;
        font-size: responsiveClamp($font-size-sm, $font-size-md, 'xs', 'xl');
        line-height: 1.5;
        font-weight: 700;
        margin: 0;

        @at-root {
          .c-entry .c-entry__content .smb-child-pages .c-entries__item:hover a .c-entry-summary__title {
            text-decoration: none;
          }
        }
      }
    }

    // 画像＋テキストの一覧
    &>.wp-block-snow-monkey-blocks-items.service-items {
      .smb-items__item__figure {
        margin: 0;
        aspect-ratio: 16/9;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .smb-items__item__title {
        font-family: $font-family-jp;
        font-size: responsiveClamp($font-size-sm, $font-size-md, 'xs', 'xl');
        line-height: 1.5;
        margin: responsiveClamp(4, 8, 'xs', 'xl') 0 0;
      }
    }

    // 関連記事
    &>.smb-recent-posts {
      .c-entries {
        display: grid;
        margin: 0;

        @include mq('md', min) {
          grid-template-columns: repeat(2, 1fr);
          gap: responsiveClamp(24, 48, 'xs', 'xl');
        }

        @include mq('xl', min) {
          grid-template-columns: repeat(3, 1fr);
        }

        .c-entries__item {
          max-width: initial;
          padding: 0;
          margin: 0;
        }
      }

      .c-entry-summary__term {
        top: 8px;
        right: 8px;
        border-radius: 4px;
        font-family: $font-family-jp;
        font-size: responsiveClamp($font-size-xs, $font-size-xs, 'xs', 'xl');
      }

      .c-entry-summary__figure {
        overflow: hidden;
        aspect-ratio: 16/9;
        margin: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .c-entry-summary__header {
        margin: responsiveClamp(8, 8, 'xs', 'xl') 0 0;
      }

      .c-entry-summary__title {
        font-family: $font-family-jp;
        line-height: 1.5;
        font-size: responsiveClamp($font-size-sm, $font-size-md, 'xs', 'xl');
        font-weight: 700;
        text-decoration: underline;
      }

      .c-entry-summary__meta {
        margin: responsiveClamp(8, 8, 'xs', 'xl') 0 0;
        justify-content: start;
        font-family: $font-family-jp;
        font-size: responsiveClamp($font-size-sm, $font-size-sm, 'xs', 'xl');
        color: $gray-500;
        font-weight: 400;
      }
    }

    // ヒストリー
    &>.l-history {
      ul {
        margin: 0 0 responsiveClamp(16, 16, 'xs', 'xl');

        @include mq(800, min) {
          margin: 0;
        }
      }
    }
  }
}

[data-has-sidebar=false] .c-entry__content>.alignfull {
  margin-left: calc(responsiveClamp(30, 100, 'xs', 'xl') * -1);
  margin-right: calc(responsiveClamp(30, 100, 'xs', 'xl') * -1);
}
