@use "../../global"as *;

.p-block2 {
  position: relative;
  padding: responsiveClamp(64, 104, 'xs', 'xl') 0 0;
  background: $gray-100;
}

.p-block2__inner1 {
  position: relative;
  z-index: 2;
  padding: 0 responsiveClamp(30, 100, 'xs', 'xl');

  @include mq('md', min) {
    grid-template-columns: responsiveClamp(200, 368, 'xs', 'xl') 1fr;
    display: grid;
    align-items: start;
    gap: responsiveClamp(24, 48, 'xs', 'xl');
    max-width: 1200px;
    margin: auto;
    box-sizing: content-box;
  }
}

.p-block2__image1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 calc(responsiveClamp(30, 100, 'xs', 'xl') * -1);

  @include mq('md', min) {
    margin: 0;
    grid-template-columns: 1fr;
  }

  @include mq('lg', min) {
    padding: 0 24px;
  }

  img {
    width: 100%;
    height: auto;

    @include mq('md', min) {
      border-radius: 10rem;
    }

    &:nth-of-type(1) {
      @include mq('md', min) {
        max-width: 300px;
      }
    }

    &:nth-of-type(2) {
      @include mq('md', min) {
        max-width: 150px;
        margin: calc(responsiveClamp(40, 64, 'xs', 'xl') * -1) 0 0 auto;
      }
    }
  }
}

.p-block2__main1 {
  margin: responsiveClamp(24, 40, 'xs', 'xl') 0 0;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);

  @include mq('md', min) {
    margin: 0;
  }
}

.p-block2__title1 {
  font-weight: 700;
  font-family: $font-family-jp;
  font-size: responsiveClamp($font-size-xl, $font-size-3xl, 'xs', 'xl');
  line-height: 1.5;
  color: $gray-900;

  br {
    display: none;

    @include mq('xl', min) {
      display: block;
    }
  }
}

.p-block2__text1 {
  font-family: $font-family-jp;
  text-align: justify;
  font-size: responsiveClamp($font-size-sm, $font-size-md, 'xs', 'xl');
  line-height: 1.7;
  margin: responsiveClamp(24, 40, 'xs', 'xl') 0 0;
  color: $gray-900;
}
