@use "../../global"as *;

.c-link1 {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: $gray-900;
  font-family: $font-family-jp;
  font-size: responsiveClamp($font-size-md, $font-size-md, 'xs', 'xl');
  gap: 16px;
  line-height: 1.5;

  svg {
    width: responsiveClamp(40, 50, 'xs', 'xl');
    height: responsiveClamp(40, 50, 'xs', 'xl');
    display: block;

    &>path {
      transition: all 0.7s $easeOutQuint;
      fill: $gray-900;

      @at-root {
        .c-link1:hover>svg>path {
          fill: $gray-100;
        }
      }
    }

    &>rect {
      transition: all 0.7s $easeOutQuint;
      fill: transparent;
      stroke: $gray-900;

      @at-root {
        .c-link1:hover>svg>rect {
          fill: $gray-900;
        }
      }
    }
  }
}
