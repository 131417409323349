@use "../../global"as *;

.p-block3 {
  position: relative;
  z-index: 1;
  padding: responsiveClamp(64, 104, 'xs', 'xl') 0 0;
  background: $gray-100;

  &::before {
    display: block;
    content: '';
    width: 100%;
    height: responsiveClamp(150, 200, 'xs', 'xl');
    position: absolute;
    top: calc(responsiveClamp(150 - 64, 200 - 104, 'xs', 'xl') * -1);
    left: 0;
    background: $other-1;

    @include mq('md', min) {
      height: 400px;
      top: -64px;
    }
  }
}

.p-block3__inner1 {}

.p-block3__list1 {
  list-style-type: none;
  margin: 0;
  padding: 0 5px;
  counter-reset: num;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;

  @include mq('sm', min) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include mq('xl', min) {
    max-width: 1600px;
    margin: auto;
    display: flex;
    justify-content: center;
    // grid-template-columns: repeat(6, 1fr);
  }
}

.p-block3__list1__item1 {}
