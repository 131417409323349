@use "../../global"as *;

.c-title4 {
  font-family: $font-family-en;
  font-weight: 700;
  text-align: center;
  color: $gray-100;
  font-size: responsiveClamp($font-size-3xl, $font-size-4xl, 'xs', 'xl');
  line-height: 1.5;
  margin: 0;
}
