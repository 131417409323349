@use "../../global"as *;

.p-block5 {
  position: relative;
  padding: responsiveClamp(64, 104, 'xs', 'xl') 0;
}

.p-block5__inner1 {
  padding: 0 responsiveClamp(30, 100, 'xs', 'xl');
  max-width: 993px;
  margin: auto;
  box-sizing: content-box;
}

.p-block5__list1 {
  list-style-type: none;
  margin: 0;
  padding: 0;

  @include mq('md', min) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.p-block5__list1__item1 {
  border-top: 1px solid $gray-700;
  padding: responsiveClamp(24, 40, 'xs', 'xl') 0;

  @include mq('md', min) {
    border: none;
    padding: 0;
    border-left: 1px solid $gray-700;
  }

  &:first-child {
    border: none;
  }
}

.p-block5__list1__item1__text1 {
  color: $gray-100;
  text-align: center;
  font-weight: 700;
  font-family: $font-family-jp;
  font-size: responsiveClamp($font-size-lg, $font-size-xl, 'md', 'xl');
  line-height: 1;
}

.p-block5__list1__item1__text2 {
  text-align: center;
  font-weight: 700;
  font-family: $font-family-en;
  font-size: responsiveClamp($font-size-5xl, $font-size-7xl, 'md', 'xl');
  line-height: 1;
  background: linear-gradient(to right, #B2A1E5, #493487);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: responsiveClamp(16, 24, 'xs', 'xl') 0 0;
}
